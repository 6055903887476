/**
 * This component inserts the unified nav HTML received by the /v1/nav/get API request, and makes
 * sure any included scripts run by parsing and injecting them at the end of the document body
 *
 * The original source is https://github.com/imbugs/script-inner-html
 */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from 'react';

let totalNumber = 0;
let loadNumber = 0;
let toLoadSrc: string[] = [];

/**
 * Appends a new script element that contains script text to the document body
 *
 * @param text The script text to run
 */
const loadScriptText = (text: string) => {
  const script = document.createElement('script');
  script.innerHTML = text;
  script.async = false;
  document.body.appendChild(script);
};

/**
 * Appends a new script element that loads an external script source to the document body
 *
 * @param src The external script source to load and run
 */
const loadScriptSrc = (src: string) => {
  totalNumber += 1;
  const script = document.createElement('script');
  script.src = src;
  script.async = false;
  script.onload = () => {
    window.dispatchEvent(new CustomEvent('loaded'));
  };
  document.body.appendChild(script);
};

/**
 * Gets script text and loads external script sources from an HTML fragment
 *
 * @param html The HTML fragment to parse
 * @returns An array of script text
 */
const getScripts = (html: string) => {
  const container = document.createElement('div');
  container.innerHTML = html;
  const scripts = [];
  const scriptElements = container.getElementsByTagName('script');
  for (let index = 0; index < scriptElements.length; index += 1) {
    const { attributes, innerText } = scriptElements[index];
    const isJson = attributes.getNamedItem('type')?.value === 'application/json';
    for (let attributeIndex = 0; attributeIndex < attributes.length; attributeIndex += 1) {
      const { name, value } = attributes[attributeIndex];
      if (name === 'src' && value) {
        loadScriptSrc(value);
      }
    }
    if (innerText.length > 0 && !isJson) {
      scripts.push(innerText);
    }
  }
  return scripts;
};

/**
 * Creates a unique ID to use for the unified nav container
 *
 * @returns A unique ID
 */
const getUniqueId = (): string => {
  const idString = Math.random().toString(36).substr(2, 16);
  return `id-${idString}`;
};

/**
 * Handler to load script text after external script sources have been loaded
 *
 * @param src An optional external script source to load
 */
const onLoaded = (src?: string) => {
  if (src) toLoadSrc.push(src);
  if (loadNumber >= totalNumber) {
    toLoadSrc.map((s) => loadScriptText(s));
    toLoadSrc = [];
  }
};

if (typeof window !== 'undefined') {
  window.addEventListener('loaded', () => {
    loadNumber += 1;
    onLoaded();
  });
}

interface UnifiedNavHtmlLoaderProps {
  html: string;
}

const UnifiedNavHtmlLoader = (props: UnifiedNavHtmlLoaderProps): JSX.Element => {
  const { html } = props;
  const [id] = useState(getUniqueId());

  useEffect(() => {
    if (html && html !== '') {
      const scripts = getScripts(html);
      scripts.map((src) => src && onLoaded(src));
    }
  }, [html]);

  return <div id={id} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default UnifiedNavHtmlLoader;
