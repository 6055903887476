import * as toastsActions from '@zola-helpers/client/dist/es/redux/toasts/toastsActions';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import * as ActionType from './types/CartActionTypes';

function cacheBust() {
  return new Date().toString();
}

function requestSize() {
  return {
    type: ActionType.REQUEST_SIZE,
  };
}

function receiveSize(response) {
  return {
    type: ActionType.RECEIVE_SIZE,
    payload: response.data && response.data.items && response.data.items.length, // temporary
  };
}

function requestCart() {
  return {
    type: ActionType.REQUEST_CART,
  };
}

function receiveCart(response) {
  return {
    type: ActionType.RECEIVE_CART,
    payload: response.data,
  };
}

function requestCreditCards() {
  return {
    type: ActionType.REQUEST_CREDIT_CARDS,
  };
}

function receiveCreditCards(response) {
  return {
    type: ActionType.RECEIVE_CREDIT_CARDS,
    payload: response.data,
  };
}
function requestApplyCredits() {
  return {
    type: ActionType.REQUEST_APPLY_CREDITS,
  };
}

function receiveApplyCredits(response) {
  return {
    type: ActionType.RECEIVE_APPLY_CREDITS,
    payload: response.data,
  };
}
function requestRemoveCreditCard() {
  return {
    type: ActionType.REQUEST_REMOVE_CREDIT_CARD,
  };
}

function receiveRemoveCreditCard(response) {
  return {
    type: ActionType.RECEIVE_REMOVE_CREDIT_CARD,
    payload: response.data,
  };
}
function requestSubmitCheckout() {
  return {
    type: ActionType.REQUEST_SUBMIT_CHECKOUT,
  };
}

function receiveSubmitCheckout(response) {
  return {
    type: ActionType.RECEIVE_SUBMIT_CHECKOUT,
    payload: response.data,
  };
}

function requestAddItemToCart() {
  return {
    type: ActionType.ADD_ITEM_TO_CART,
  };
}

function receiveAddItemToCart(json) {
  return {
    type: ActionType.ADDED_ITEM_TO_CART,
    payload: json,
  };
}

function addItem2() {
  return {
    type: ActionType.ADD_ITEM,
  };
}

function addedItem(json) {
  return {
    type: ActionType.ADDED_ITEM,
    payload: json,
  };
}

export function unloadCart() {
  return {
    type: ActionType.UNLOAD_CART,
  };
}

export function resetCart() {
  return {
    type: ActionType.RESET_CART,
  };
}

export function getCartSize() {
  return (dispatch) => {
    dispatch(requestSize());
    return ApiService.get(`/api/v0/cart?cache=${cacheBust()}`).then((json) =>
      dispatch(receiveSize(json))
    );
  };
}

export function getCart() {
  return (dispatch) => {
    dispatch(requestCart());
    return ApiService.get(`/api/v0/cart?cache=${cacheBust()}`).then((json) =>
      dispatch(receiveCart(json))
    );
  };
}

export function addItemToCart(body) {
  return (dispatch) => {
    dispatch(requestAddItemToCart());
    return ApiService.post('/web-store-api/v1/cart/add-item', body)
      .then((json) => dispatch(receiveAddItemToCart(json)))
      .catch((response) => {
        const error = response.response && response.response.error && response.response.error;
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error || response;
      });
  };
}

export function addItem(addItemRequest) {
  return (dispatch) => {
    dispatch(addItem2());
    return ApiService.post('/api/v0/cart/item', addItemRequest)
      .then((json) => {
        // TODO: This may not work with ApiService so may need to be in the catch
        if (json && json.errorMessage) {
          const error = new Error(json.errorMessage);
          error.response = json;
          dispatch(toastsActions.negative({ headline: json.errorMessage }));
          throw error;
        } else {
          dispatch(addedItem(json));
          return json;
        }
      })
      .catch((error) => {
        dispatch(toastsActions.negative({ headline: error.response.errorMessage }));
        throw error.response;
      });
  };
}

export function removeItem(id) {
  return (dispatch) => {
    dispatch(requestCart());
    return ApiService.delete(`/api/v0/cart/item/${id}`).then((json) => dispatch(receiveCart(json)));
  };
}

export function removeItems(itemIds) {
  return (dispatch) => {
    dispatch(requestCart());
    return ApiService.post('/api/v0/cart/remove-items', { itemIds }).then((json) =>
      dispatch(receiveCart(json))
    );
  };
}

export function updateItemQuantity(id, quantity) {
  return (dispatch) => {
    dispatch(requestCart());
    return ApiService.post(`/api/v0/cart/item/${id}`, { quantity })
      .then((json) => {
        if (json && json.data.errorMessage) {
          dispatch(toastsActions.negative({ headline: json.data.errorMessage }));
        }
        return dispatch({
          type: ActionType.RECEIVE_CART,
          payload: json.data.cart,
        });
      })
      .catch((error) => {
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error;
      });
  };
}

export function getCreditCards() {
  return (dispatch) => {
    dispatch(requestCreditCards());
    return ApiService.get('/api/v0/cart/credit-cards/get-credit-cards').then((json) =>
      dispatch(receiveCreditCards(json))
    );
  };
}

export function removeCreditCard(cardId) {
  return (dispatch) => {
    dispatch(requestRemoveCreditCard());
    return ApiService.post('/api/v0/cart/credit-cards/remove-credit-card', cardId)
      .then((json) => dispatch(receiveRemoveCreditCard(json)))
      .catch((response) => {
        const error = response.response && response.response.error && response.response.error;
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error || response;
      });
  };
}
export function submitCheckout(body) {
  return (dispatch) => {
    dispatch(requestSubmitCheckout());
    return ApiService.post('/api/v0/cart/checkout/submit-checkout', body)
      .then((json) => dispatch(receiveSubmitCheckout(json)))
      .catch((error) => {
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error;
      });
  };
}

export function applyCredits(isExpedited) {
  return (dispatch) => {
    dispatch(requestApplyCredits());
    return ApiService.post('/api/v0/cart/checkout/apply-credit', isExpedited)
      .then((json) => dispatch(receiveApplyCredits(json)))
      .catch((response) => {
        const error = response.response && response.response.error && response.response.error;
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error || response;
      });
  };
}
