import React from 'react';
import { Footer } from '@zola/zola-ui/src/components/Footer';
import EmailCapture from '../form/EmailCapture';

import './StoreFooter.modules.less';

export const StoreFooter = ({ ...props }) => (
  <>
    <EmailCapture />
    <Footer {...props} />
  </>
);
export default StoreFooter;
