import uuidv4 from 'uuid/v4';

// TODO / NOTE : Most of these events should be added to zola-helpers

export const customEventNames = {
  REGISTRY_ITEM_ADDED: 'Registry Item Added',
  PRODUCT_ADDED: 'Product Added',
  PRODUCT_CLICKED: 'Product Clicked',
  PRODUCT_VIEWED: 'Product Viewed',
  PRODUCT_LIST_VIEWED: 'Product List Viewed',
  CTA_CLICKED: 'CTA Clicked',
  SELECT_ALL_CLICKED: 'Clicked Select All', // quick add packs detail page
  SHOP_ALL_CLICKED: 'Clicked Shop All', // quick add packs detial page
};

const FACETS_TRACKING_EVENT_KEY_MAP = {
  PRICE_RANGE: 'facet_price',
  COLOR: 'facet_color',
  BRAND: 'facet_brand',
};

export function getUUIDv4() {
  return uuidv4();
}

function getPageContext() {
  return {
    path: window.location.pathname,
    search: window.location.search,
    referrer: (window && window.zola && window.zola.referrer) || document.referrer,
    url: window.location.href,
    title: document.title,
  };
}

export function trackPageLoaded() {
  if (window.analytics && window.analytics.page) {
    const payload = getPageContext();
    window.analytics.page({ ...payload });
  }
}

export function trackEvent(eventName, payload, options) {
  const optionsFinal = options || {};
  optionsFinal.page = getPageContext();
  if (window.analytics && window.analytics.track) {
    window.analytics.track(eventName, payload, optionsFinal);
  }
}

export function trackOnboardingStarted(businessUnit, component, isExistingUser = null) {
  if (window.analytics && window.dataLayer) {
    window.dataLayer.push({ is_existing_user: isExistingUser });
  }
  const payload = {
    business_unit: businessUnit,
    component,
  };
  trackEvent('Onboarding Started', payload);
}

export function trackFlowStarted(flowName, flowVersion, flowRunId) {
  const payload = {
    flow_name: flowName,
    flow_version: flowVersion,
    flow_run_id: flowRunId,
  };
  trackEvent('Flow Started', payload);
}

export function trackFlowCompleted(flowName, flowVersion, flowRunId) {
  const payload = {
    flow_name: flowName,
    flow_version: flowVersion,
    flow_run_id: flowRunId,
  };
  trackEvent('Flow Completed', payload);
}

export function trackFlowStepViewed(flowName, flowVersion, flowRunId, stepName, stepNumber) {
  const payload = {
    flow_name: flowName,
    flow_version: flowVersion,
    flow_run_id: flowRunId,
    step_name: stepName,
    step_number: stepNumber,
  };
  trackEvent('Flow Step Viewed', payload);
}

export function trackFlowStepCompleted(flowName, flowVersion, flowRunId, stepName, stepNumber) {
  const payload = {
    flow_name: flowName,
    flow_version: flowVersion,
    flow_run_id: flowRunId,
    step_name: stepName,
    step_number: stepNumber,
  };
  trackEvent('Flow Step Completed', payload);
}

export function trackOnboardingStepCompleted(
  businessUnit,
  component,
  stepKey,
  stepName,
  questionnaireVersion
) {
  const payload = {
    step_key: stepKey,
    step_name: stepName,
    business_unit: businessUnit,
    component,
    questionnaire_version: questionnaireVersion,
  };
  trackEvent('Onboarding Step Completed', payload);
}

export function trackRecommendation(action, recommendationData) {
  trackEvent(`CTA Recommendation ${action}`, recommendationData);
}

/**
 * Helper to get categry facet data and depth of the current node in category tree
 * @param {Number} nodeId - id of the category tree we get data for
 * @param {Object} categoryTree - category tree object where the key is being looked up in
 * @return {Object} - returns object with selected category key mapped to node name, along
 * with it's patents
 */
export function getCategoryEventDetails(nodeId, categoryTree) {
  const categoryDepth = 1;
  const nodeLevels = {};
  (function findNode(id, tree, i) {
    return tree.find((node) => {
      if (node.id === id) {
        nodeLevels[`facet_category_${i}`] = node.name;
        return node;
      }
      const result = findNode(id, node.children, i + 1);
      if (result) {
        nodeLevels[`facet_category_${i}`] = node.name;
      }
      return result;
    });
  })(nodeId, categoryTree, categoryDepth);
  return nodeLevels;
}

/**
 * Gets facets event data with selected facets options mapped to their labels
 * @param {Object} selectedFacets - facets that are selected
 * @param {Array<Object>} facets - all facets with label information
 * @return {Object} - facet data formatted for event
 */
export function getFacetsEventDetails(selectedFacets, facets) {
  const facetsEventData = {};
  Object.keys(selectedFacets).forEach((facetKey) => {
    const eventKey = FACETS_TRACKING_EVENT_KEY_MAP[facetKey];
    if (eventKey) {
      // find parent facet object with options
      const facet = facets.find((n) => n.key === facetKey);
      // look through options to get labels
      const selectedOptions = Object.keys(selectedFacets[facetKey]);
      facetsEventData[eventKey] =
        facet &&
        facet.options.filter((n) => selectedOptions.indexOf(n.value) > -1).map((n) => n.label);
    }
  });
  return facetsEventData;
}

function checkGTMBrand(eventData) {
  // if the brand is not defined - clear it on the GTM data layer because we check for it to determine if the product added is a cash gift.
  if (!eventData.brand && window.dataLayer && window.dataLayer.push) {
    window.dataLayer.push({
      brand: undefined,
    });
  }
}

export function trackProductAdded(eventData) {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.PRODUCT_ADDED, eventData);
}

export function trackProductClicked(eventData) {
  if (!eventData) return;
  trackEvent(customEventNames.PRODUCT_CLICKED, eventData);
}

export function trackCtaClicked(eventData) {
  if (!eventData) return;
  trackEvent(customEventNames.CTA_CLICKED, eventData);
}

export function trackProductViewed(eventData) {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.PRODUCT_VIEWED, eventData);
}

export function trackRegistryItemAdded(eventData) {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.REGISTRY_ITEM_ADDED, eventData);
}

export function trackProductListViewed(eventData) {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.PRODUCT_LIST_VIEWED, eventData);
}

export function trackSelectAllClicked(eventData) {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.SELECT_ALL_CLICKED, eventData);
}

export function trackShopAllClicked(eventData) {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.SHOP_ALL_CLICKED, eventData);
}
