export function detectMobileDevice() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  return false;
}

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }
  return 'unknown';
}

export function detectIOS() {
  return getMobileOperatingSystem() === 'iOS';
}

export function detectAndroid() {
  return getMobileOperatingSystem() === 'Android';
}

export function ieVersion() {
  const ua = window.navigator.userAgent;
  let ie = 0;

  if (ua.indexOf('Trident/7.0') > -1) {
    ie = 11;
  } else if (ua.indexOf('Trident/6.0') > -1) {
    ie = 10;
  } else if (ua.indexOf('Trident/5.0') > -1) {
    ie = 9;
  }

  return ie;
}

export default detectMobileDevice;
