import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Sticky from 'react-stickynode';
import _debounce from 'lodash/debounce';
import { isDesktop } from '@zola-helpers/client/dist/es/util/responsive';

import UnifiedNavHtmlLoader from '../UnifiedNavHtmlLoader';

import initNavData from './initNavData';

// make sure this website nav gets loaded in a <StickyContainer/>

const getNavHeight = () => (isDesktop() ? 160 : 60);

class UnifiedNavV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: '',
      height: getNavHeight(),
    };

    this.refreshNavHeight = _debounce(this.refreshNavHeight.bind(this), 200);
    this.handleInitNavData = this.handleInitNavData.bind(this);
  }

  componentDidMount() {
    const { fetchNav } = this.props;

    window.addEventListener('NAV_LOADED', this.handleInitNavData);
    window.addEventListener('resize', this.refreshNavHeight);

    fetchNav().then((nav) => {
      this.setState({ nav });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.refreshNavHeight);
    window.removeEventListener('NAV_LOADED', this.handleInitNavData);
  }

  handleInitNavData() {
    const {
      user,
      showPromo,
      fetchUserContext,
      fetchCategories,
      fetchWeddingShopCategories,
      activePrimaryLink,
      activeSecondaryLink,
      disablePrimaryNavCollapse,
      disableSecondary,
      isWeddingBoutiquePage,
      defaultTab,
    } = this.props;

    initNavData({
      user,
      showPromo,
      fetchUserContext,
      fetchCategories,
      fetchWeddingShopCategories,
      activePrimaryLink,
      activeSecondaryLink,
      disablePrimaryNavCollapse,
      disableSecondary,
      isWeddingBoutiquePage,
      defaultTab,
    });
  }

  refreshNavHeight() {
    const { height } = this.state;
    const newHeight = getNavHeight();
    if (height !== newHeight) {
      this.setState({ height: newHeight });
    }
  }

  render() {
    const { styleOverride, className } = this.props;
    const { nav, height } = this.state;
    const defaultStyles = {
      zIndex: 500,
      height,
      transform: 'none',
    };
    return (
      <div className={cx('unified-nav', className)} style={{ ...defaultStyles, ...styleOverride }}>
        <Sticky innerZ={500} enableTransforms={false}>
          <UnifiedNavHtmlLoader html={nav} />
        </Sticky>
      </div>
    );
  }
}

UnifiedNavV2.propTypes = {
  activePrimaryLink: PropTypes.string,
  activeSecondaryLink: PropTypes.string,
  user: PropTypes.shape({}),
  fetchUserContext: PropTypes.func.isRequired,
  fetchNav: PropTypes.func.isRequired,
  styleOverride: PropTypes.shape({
    height: PropTypes.number,
    backgroundColor: PropTypes.string,
    zIndex: PropTypes.number,
  }),
  fetchCategories: PropTypes.func.isRequired,
  fetchWeddingShopCategories: PropTypes.func.isRequired,
  showPromo: PropTypes.bool,
  disablePrimaryNavCollapse: PropTypes.bool,
  disableSecondary: PropTypes.bool,
  isWeddingBoutiquePage: PropTypes.bool,
  className: PropTypes.string,
  defaultTab: PropTypes.string,
};

UnifiedNavV2.defaultProps = {
  user: null,
  showPromo: false,
  disablePrimaryNavCollapse: false,
  disableSecondary: false,
  defaultTab: 'HOME_STORE',
};

export default UnifiedNavV2;
