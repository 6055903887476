/* eslint-disable arrow-body-style,no-plusplus,jsx-a11y/mouse-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './EmailCapture.less';
import { InputFieldV2 } from '@zola/zola-ui/src/components/Form/InputFieldV2';
import { ButtonV2 } from '@zola/zola-ui/src/components/Button';
import { EMAIL } from './commonValidations';
import ApiService from '../../../util/apiService';

const preauthSuccess =
  "You're now subscribed to Zola Home's emails! New to Zola? Expect a discount code in your inbox shortly.";
const postauthSuccess = "You're now subscribed to Zola Home's emails! Catch us in your inbox soon.";

const EmailCaptureForm = () => {
  const user = useSelector((state) => state.user);
  const isGuest = user && user.userContext && user.userContext.is_guest;
  const [successMessage, setSuccessMessage] = useState('');
  const requestEmailCapture = (body) => {
    return ApiService.put('/web-store-api/v1/discount/capture-email', body).then(() => {
      const message = isGuest ? preauthSuccess : postauthSuccess;
      setSuccessMessage(message);
    });
  };

  const [email, setEmail] = useState('');
  useEffect(() => {
    if (user && user.userContext && user.userContext.email) {
      setEmail(user.userContext.email);
    }
  }, [user]);

  const [errorMessage, setErrorMessage] = useState('');
  const handleBlur = () => {
    setErrorMessage(EMAIL(email));
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    if (errorMessage) {
      setErrorMessage(EMAIL(email));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (errorMessage) {
      return;
    }
    requestEmailCapture({
      email,
    });
  };
  if (successMessage) {
    return (
      <div className="email-content__success-message">
        <i className="zolaicon zolaicon-checkmark" />
        <p>{successMessage}</p>
      </div>
    );
  }

  return (
    <div className="email-capture__input-row">
      <InputFieldV2
        value={email}
        placeholder="Email"
        className="email-capture__input"
        onChange={handleChange}
        onBlur={handleBlur}
        errorMessage={errorMessage}
      />
      <ButtonV2 className="email-capture__button--submit" onClick={handleSubmit}>
        Submit
      </ButtonV2>
    </div>
  );
};

export default EmailCaptureForm;
