import { EventTypes } from 'redux-segment';
import ApiService from '../util/apiService';
import * as ActionType from './types/UserActionTypes';
import { detectMobileDevice } from '../util/detectBrowser';

export function requestUser() {
  return {
    type: ActionType.REQUEST_USER,
  };
}

export function receiveUser(response) {
  return {
    type: ActionType.RECEIVE_USER,
    payload: response,
  };
}

export function identifyUser(forceIdentify) {
  return (dispatch, getState) => {
    const { user } = getState();
    const userContext = user.userContext || {};
    const { hasIdentified } = user;

    if (!userContext.is_guest && (!hasIdentified || forceIdentify)) {
      const meta = {
        analytics: {
          eventType: EventTypes.identify,
          eventPayload: {
            userId: userContext.object_id,
            traits: {
              email: userContext.email || '',
              first_name: userContext.first_name || '',
              last_name: userContext.last_name || '',
              role:
                userContext.user_role_account_weddings &&
                userContext.user_role_account_weddings.length > 0
                  ? userContext.user_role_account_weddings[0].role
                  : '',
              name: userContext.first_name
                ? `${userContext.first_name} ${userContext.last_name}`
                : '',
            },
          },
        },
      };

      if (window.analytics && window.dataLayer) {
        window.dataLayer.push({
          event: 'User Identified',
          userId: userContext.object_id,
        });
      }

      dispatch({
        type: ActionType.IDENTIFY_USER,
        meta,
      });
    }
  };
}

export function identifyGuestUser(forceIdentify) {
  return (dispatch, getState) => {
    const userContext = getState().user.userContext || {};
    const { hasGuestIdentified } = getState().user;
    if (userContext.is_guest && (!hasGuestIdentified || forceIdentify)) {
      const meta = {
        analytics: {
          eventType: EventTypes.identify,
          eventPayload: {
            userId: userContext.object_id,
            traits: {
              role: 'VISITOR',
            },
          },
        },
      };

      dispatch({
        type: ActionType.IDENTIFY_GUEST_USER,
        meta,
      });
    }
  };
}

/**
 * Identify a user within Sailthru.
 * Result sets sailthru_hid with a SHA256 hash of the email
 * SEE: https://getstarted.sailthru.com/developers/api-client/javascript/#userSignUp
 * @param {string} email Users email
 * @returns {Promise<any>}
 */
export function identifyUserSailthru(email, newUserData) {
  return new Promise((resolve) => {
    // Ignore if Sailthru is not available
    if (typeof window.Sailthru === 'undefined') {
      resolve();
      return;
    }

    // In case something goes terribly wrong
    const forceResolveTimeout = window.setTimeout(resolve, 1000);

    const vars = { email };

    if (newUserData) {
      vars.first_account_created = newUserData.business_unit_source;
      vars.first_account_device_type = detectMobileDevice() ? 'Mobile Web' : 'Desktop';
    }

    const sailthruRequest = {
      email,
      vars,
      onSuccess: () => {
        clearTimeout(forceResolveTimeout);
        resolve();
      },
      onError: () => {
        clearTimeout(forceResolveTimeout);
        resolve();
      },
    };

    try {
      window.Sailthru.integration('userSignUp', sailthruRequest);
    } catch (error) {
      resolve();
    }
  });
}

export function getUserContext() {
  return (dispatch, getState) => {
    dispatch(requestUser());
    return ApiService.get('/website-nav/web-api/v1/user/get')
      .then((json) => {
        dispatch(receiveUser(json));
        return json;
      })
      .then((json) => {
        // Temporarily set email for BounceX usage
        window.zola = window.zola || {};
        window.zola.tracking = {
          email: getState().user.userContext.email || '',
        };
        return json;
      })
      .then((json) => {
        dispatch(identifyUser());
        return json;
      })
      .catch((e) => e); // gives caller the opportunity to handle the error
  };
}
