function convertCurrencyFormat(
  amount,
  currency = 'USD',
  minimumFractionDigits = 0,
  maximumFractionDigits = 0
) {
  return amount.toLocaleString('en', {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  });
}

export default convertCurrencyFormat;
