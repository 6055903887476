import experimentFlagsHelper from '@zola-helpers/client/dist/es/util/experimentFlagsHelper';

// add keys in admin and stage-admin
// Stage-Admin - https://stage-admin.zola.com/experiments
// Admin - https://admin.zola.com/experiments

// Experiment Keys
// export const EXAMPLE_EXPERIMENT = 'example-experiment';
export const HOLIDAY_SHOPS = 'holiday-shops'; // Seasonal holiday shop (non-card) highlights
export const HOMESTORE_LOGIN_MODAL = 'homestore-login-modal'; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-3878

const FALLBACK_VARIATIONS = {
  // [EXAMPLE_EXPERIMENT]: experimentFlagsHelper.createExperimentFlags(),
  [HOLIDAY_SHOPS]: experimentFlagsHelper.createExperimentFlags(),
  [HOMESTORE_LOGIN_MODAL]: experimentFlagsHelper.createExperimentFlags(),
};

export default experimentFlagsHelper.makeExperimentFlags(FALLBACK_VARIATIONS);
