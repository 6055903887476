import moment from 'moment';

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
const TIME_REGEX = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]?([ ]? [a|p]m)?$/;
const SLUG_REGEX = /^[a-za-z0-9]+[a-za-z0-9-_]*[a-za-z0-9]+$/;
const ZIP_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
const PHONE_REGEX = /(^(?:\([2-9]\d{2}\) ?|[2-9]\d{2}(?:-?| ?))[2-9]\d{2}[- ]?\d{4}$)/;
const MONEY_REGEX = /^[0-9]+(\.[0-9]{1,2})?$/;
const URL_REGEX =
  /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;
export const REQUIRED = (value) => {
  if (typeof value === 'string') {
    return value && value.trim() ? undefined : 'Required';
  }
  return value ? undefined : 'Required';
};

export const EMAIL_MATCH = (emailToCompare) => (newEmail) => {
  if (!emailToCompare || emailToCompare.trim().toLowerCase() !== newEmail.trim().toLowerCase()) {
    return 'Email does not match.';
  }
  return undefined;
};

export const COLOR = (value) => /^#([A-Fa-f0-9]{3}$)|([A-Fa-f0-9]{6}$)/.test(value);

export const EMAIL = (value) =>
  value && !EMAIL_REGEX.test(value) ? 'Invalid email address' : undefined;

export const MONEY = (value) => (!MONEY_REGEX.test(value) ? 'Invalid dollar amount.' : undefined);

export const TIME = (value) => (value && !TIME_REGEX.test(value) ? 'Invalid time' : undefined);

export const isDateValid = (date) => {
  const aDate = moment(date, ['MM-DD-YYYY', 'MM/DD/YYYY', 'MMDDYYYY'], true);
  return aDate.isValid();
};

export const WEDDING_GIFT_DATE = (lastAvailableDate, isWeddingDate) => (value) => {
  const isValid = isDateValid(value);
  const today = moment().utc();
  const isInPast = isValid && moment(value).diff(today, 'days') < 0;
  const isAfterllowedDate = lastAvailableDate && moment(lastAvailableDate).utc().diff(value) < 0;
  const isInvalid = value && value.length > 0 && !isValid;
  if (isInvalid) {
    return 'Please enter a valid date.';
  }
  if (isWeddingDate && (isInPast || isAfterllowedDate)) {
    return `The date must be between today and the couple’s wedding date ${lastAvailableDate}.`;
  }
  if (!isWeddingDate && (isInPast || isAfterllowedDate)) {
    return `The date must be between today and ${lastAvailableDate}.`;
  }
  return undefined;
};

export const ZIP = (value) => (value && !ZIP_REGEX.test(value) ? 'Invalid zip code' : undefined);

const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

// short warning message for short inputs
export const maxLengthShort = (max) => (value) =>
  value && value.length > max ? `Over max ${value.length}/${max}` : undefined;

export const minLength = (min) => (value) =>
  value && value.length > 0 && value.length < min
    ? `Must be at least ${min} characters`
    : undefined;

export const PHONE = (value) =>
  value && !PHONE_REGEX.test(value) ? 'Please enter a valid phone number' : undefined;

export const NUMBER = (value) => {
  const number = Number(value);
  const isNumber = Number.isInteger(number);
  if (value && !isNumber) {
    return 'Invalid number';
  }
  return undefined;
};

export const GREATER_THAN_ZERO = (value) => {
  const number = Number(value);
  const isNumber = Number.isInteger(number);
  if (isNumber && number <= 0) {
    return 'Please enter a number greater than 0.';
  }
  return undefined;
};

export const HEIGHT_FEET = (value) => {
  const number = Number(value);
  const isNumber = Number.isInteger(number);

  const isValidHeight = number >= 0 && number <= 11;
  if (isNumber && !isValidHeight) {
    return 'Invalid height';
  }
  return undefined;
};

export const MAX_LENGTH_200 = maxLength(200);
export const MAX_LENGTH_400 = maxLength(400);
export const MAX_LENGTH_1000 = maxLength(1000);
export const MAX_LENGTH_5 = maxLength(5);
export const MAX_LENGTH_20 = maxLength(20);
export const MAX_LENGTH_30 = maxLength(30);
export const MAX_LENGTH_50 = maxLength(50);
export const MAX_LENGTH_40 = maxLength(40);
export const MAX_LENGTH_60 = maxLength(60);
export const MAX_LENGTH_80 = maxLength(80);
export const MAX_LENGTH_100 = maxLength(100);
export const MAX_LENGTH_5000 = maxLength(5000);
export const MAX_LENGTH_2000 = maxLength(2000);
export const MAX_LENGTH_800 = maxLength(800);
export const MAX_LENGTH_150 = maxLength(150);
export const MAX_LENGTH_1 = maxLengthShort(1);

export const MIN_LENGTH_50 = minLength(50);
export const MIN_LENGTH_4 = minLength(4);

export const fakeValid = () => undefined;
export const PASSWORD = (value) =>
  value && value.length < 8 ? 'Password must be at least 8 characters long.' : undefined;
export const NOT_ZOLA = (value) =>
  value && value.replace('zola', '') !== value
    ? 'Your Zola registry not showing up? Call us at 408-657-9652 to add it.'
    : undefined;
export const SLUG = (value) =>
  value && !SLUG_REGEX.test(value)
    ? 'Please use only letters, numbers, underscores and hyphens. Cannot start or end with a hyphen or underscore.'
    : undefined;

export const DATE_AFTER_NOW = (value) => {
  const date = moment.utc(value, 'MM/DD/YYYY');
  const now = moment().format('MM/DD/YYYY');
  if (date.isBefore(now)) {
    return 'Hmm… this looks like a past date.';
  }
  if (date.isAfter(moment().add(10, 'y'), 'year')) {
    return `Date cannot be after ${moment().add(10, 'y').format('YYYY')}`;
  }
  return undefined;
};

export const URL = (value) =>
  value && !URL_REGEX.test(value) ? 'Please enter a valid web address.' : undefined;
