import ApiService from '../util/apiService';
import * as ActionType from './types/NavActionTypes';

const cashFunds = {
  title: 'Cash Funds',
  path: '/shop/honeymoon-cash-funds',
};

const requestCategories = () => ({
  type: ActionType.REQUEST_CATEGORY,
});

const receiveCategories = (response) => ({
  type: ActionType.RECEIVE_CATEGORY,
  payload: response,
});

export const getCategories = () => (dispatch) => {
  dispatch(requestCategories());
  return ApiService.get('/web-store-api/v1/nav/get/categories')
    .then((json) => {
      json.categories.push(cashFunds);
      return json;
    })
    .then((json) => {
      dispatch(receiveCategories(json));
      return json;
    })
    .catch((e) => e); // error handled to be handled UnifiedNavV2
};

export const getWeddingShopCategories = () => (dispatch) => {
  dispatch(requestCategories());

  return ApiService.get('/web-store-api/v1/nav/wedding-shop')
    .then((json) => {
      dispatch(receiveCategories(json));
      return json;
    })
    .catch((e) => e); // error handled to be handled UnifiedNavV2
};

export function getNav() {
  return () => ApiService.get('/web-store-api/v1/nav/get', {}, {}, 'text').then((text) => text);
}
