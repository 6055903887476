import { connect } from 'react-redux';
import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';

import * as UserActions from '../../../../actions/UserActions';
import { getCategories, getNav, getWeddingShopCategories } from '../../../../actions/NavActions';

import UnifiedNavV2 from './UnifiedNavV2';
import { getUserContext } from '../../../../selectors/userSelectors';

const mapStateToProps = (state) => ({
  user: getUserContext(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserContext: () => dispatch(UserActions.getUserContext()),
  fetchNav: () => dispatch(getNav()),
  fetchCategories: () => dispatch(getCategories()),
  fetchWeddingShopCategories: () => dispatch(getWeddingShopCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnifiedNavV2);
export { NAV_DATA };
