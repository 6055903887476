import { getBoutiqueNavItems } from '@zola-helpers/client/dist/es/nav/boutiqueNavData';
import { getRegistryNavItems } from '@zola-helpers/client/dist/es/nav/registryNavData';
import experimentFlags, { HOLIDAY_SHOPS } from '../../../../util/experimentFlags';

const initNavData = ({
  user,
  showPromo,
  fetchUserContext,
  fetchCategories,
  fetchWeddingShopCategories,
  activePrimaryLink,
  activeSecondaryLink,
  disablePrimaryNavCollapse,
  disableSecondary,
  isWeddingBoutiquePage,
  defaultTab,
}) => {
  const fetchCategoriesAction = isWeddingBoutiquePage
    ? fetchWeddingShopCategories
    : fetchCategories;

  return Promise.all([!user ? fetchUserContext() : Promise.resolve(user), fetchCategoriesAction()])
    .then(([userContextResponse, categoriesResponse]) => {
      let userContext;
      let categories;
      let secondaryData;

      if (userContextResponse instanceof Error) {
        userContext = null;
      } else {
        userContext = userContextResponse;
      }
      if (categoriesResponse instanceof Error) {
        categories = [];
      } else {
        categories = categoriesResponse;
      }
      if (!disableSecondary) {
        if (isWeddingBoutiquePage) {
          secondaryData = getBoutiqueNavItems(userContext, categories);
        } else {
          const showHolidayData = experimentFlags.getFlag(HOLIDAY_SHOPS, userContext);
          secondaryData = getRegistryNavItems(userContext, categories, showHolidayData, true);
        }
      }

      window.dispatchEvent(
        new CustomEvent('INIT_NAV_DATA', {
          detail: {
            secondaryData,
            userContext,
            showPromo,
            activePrimaryLink,
            activeSecondaryLink,
            disablePrimaryNavCollapse,
            defaultTab,
          },
        })
      );

      return [userContextResponse, userContextResponse];
    })
    .catch((e) => {
      console.log('promise all error', e); // eslint-disable-line
    });
};

export default initNavData;
