import React from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import EmailCaptureForm from './EmailCaptureForm';
import './EmailCapture.less';
import couch from '../../../assets/images/EmailCapture/couch.svg';
import FeatureFlags from '../../../util/featureFlags';

const Offer = ({ isGuest }) => {
  if (!isGuest) return null;

  return (
    <p className="email-capture__offer-details">
      *Offer valid for first-time Zola users on full-price items only. Exclusions apply.{' '}
      <a href="/discount-terms/609331">Terms & Conditions</a>
    </p>
  );
};
Offer.propTypes = {
  isGuest: propTypes.bool,
};

const Dek = ({ isGuest }) => {
  if (!isGuest) return <h3>Get the Zola Home scoop on sales, new arrivals, and more!</h3>;

  return (
    <h3>
      Get Zola in your inbox{' '}
      <span className="email-capture__fine-print-span">
        (and 15% off your first Home Store purchase*).
      </span>
    </h3>
  );
};

Dek.propTypes = {
  isGuest: propTypes.bool,
};

const EmailCapture = () => {
  // to-do: investigate getting the registry store for user
  const user = useSelector((state) => state.user);
  const isGuest = user && user.userContext && user.userContext.is_guest;

  if (!FeatureFlags.get('showEmailCapture')) return null;

  return (
    <div className="email-capture-component">
      <img src={couch} className="email-capture__image--couch" alt="" />

      {/* mobile */}
      <div className="email-content--non-desktop">
        <Dek isGuest={isGuest} />
        <EmailCaptureForm />
        <Offer isGuest={isGuest} />
      </div>

      {/* desktop */}
      <div className="email-content--desktop">
        <div className="email-capture__offer-details-row">
          <Dek isGuest={isGuest} />
          <Offer isGuest={isGuest} />
        </div>
        <EmailCaptureForm />
      </div>
    </div>
  );
};

EmailCapture.propTypes = {};

export default EmailCapture;
