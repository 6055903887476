import _get from 'lodash/get';
import _find from 'lodash/find';

export const getWeddingInfo = (state) => {
  const userContext = state.user && state.user.userContext;
  const weddingUserRole =
    userContext.user_role_account_weddings &&
    _find(
      userContext.user_role_account_weddings,
      (userRole) => userRole.role === 'OWNER' || userRole.role === 'PARTNER'
    );

  if (weddingUserRole) {
    return {
      owner_first_name: weddingUserRole.owner_first_name,
      owner_last_name: weddingUserRole.owner_last_name,
      partner_first_name: weddingUserRole.partner_first_name,
      partner_last_name: weddingUserRole.partner_last_name,
      wedding_date: weddingUserRole.wedding_date,
    };
  }

  return null;
};

export const getUserContext = (state) => _get(state, 'user.userContext');

export const getUserWeddingDate = (state) =>
  _get(state, 'user.userContext.user_role_account_weddings[0].wedding_date');

export default {
  getWeddingInfo,
  getUserContext,
  getUserWeddingDate,
};
